const googleCalendarDefaultLink = 'https://calendar.google.com/calendar/r/eventedit?dates=';
const outlookCalendarDefaultLink = 'https://outlook.live.com/calendar/0/deeplink/compose';
const outlook365CalendarDefaultLink = 'https://outlook.office.com/calendar/deeplink/compose';

function toNumber(envVar: string | undefined, defaultValue: number) {
  const num = Number(envVar);
  return envVar === undefined || Number.isNaN(num) ? defaultValue : num;
}

const config = {
  isLocal: import.meta.env.VITE_IS_LOCAL === 'true',
  FEurl: import.meta.env.VITE_FE_URL,
  accessibilityUrl: import.meta.env.VITE_ACCESSIBILITY_URL!,
  copyrightUrl: import.meta.env.VITE_COPYRIGHT_URL!,
  openTokApiKey: import.meta.env.VITE_OPENTOK_API_KEY,
  aimyUrl: import.meta.env.VITE_AIMY_URL,
  recaptchaKey: import.meta.env.VITE_RECAPTCHA_KEY,
  launchDarkly: {
    useDefaultUser: import.meta.env.VITE_LAUNCHDARKLY_USE_DEFAULT_USER === 'true',
    defaultUserId: import.meta.env.VITE_LAUNCHDARKLY_DEFAULT_USER_ID,
    clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_SIDE_ID!,
    options: {
      allAttributesPrivate: import.meta.env.VITE_LAUNCHDARKLY_ALL_ATTRIBUTES_PRIVATE === 'true',
      baseUrl: import.meta.env.VITE_LAUNCHDARKLY_RELAY_PROXY_URL,
      eventsUrl: import.meta.env.VITE_LAUNCHDARKLY_RELAY_PROXY_URL,
      streamUrl: import.meta.env.VITE_LAUNCHDARKLY_RELAY_PROXY_URL,
      streaming: false,
      sendEvents: false,
      bootstrap: 'localStorage' as 'localStorage'
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  },
  localizations: {
    debug: import.meta.env.VITE_LOCALIZATION_DEBUG === 'true',
    backendUri: import.meta.env.VITE_LOCALIZATION_BACKEND_URI,
    forceUseOnlyBundledResources:
      import.meta.env.VITE_LOCALIZATION_FORCE_USE_ONLY_BUNDLED_RESOURCES === 'true'
  },
  sentry: {
    active: import.meta.env.VITE_SENTRY_ACTIVE === 'true',
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dataSourceName: import.meta.env.VITE_SENTRY_DSN
  },
  documents: {
    baseUrl: import.meta.env.VITE_DOCUMENTS_BASE_URL
  },
  showPhoneDialin: true,
  hotjar: {
    enabled: true,
    snippetVersion: parseInt(import.meta.env.VITE_HOTJAR_SNIPPET_VERSION!, 10),
    trackingCode: parseInt(import.meta.env.VITE_HOTJAR_TRACKING_CODE!, 10)
  },
  useComponentsLibrary: false,
  screenSharingFeature: true,
  sessionOverview: {
    active: true,
    displaySessionIdColumn: true
  },
  daimlerOrganizationId: import.meta.env.VITE_DAIMLER_ORG_ID,
  sessionNotificationReminderTrigger: import.meta.env.VITE_FA_SESSION_REMINDER_HOURS_TRIGGER || 36,
  auth0: {
    domain: import.meta.env.VITE_AUTH0_DOMAIN!,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID!,
    redirectUri: import.meta.env.VITE_AUTH0_REDIRECT_URI!,
    tokenExchangeRefreshRateInSeconds: import.meta.env.VITE_TOKEN_EXCHANGE_REFRESH_RATE || 50,
    tokenExchangeThresholdInSeconds: import.meta.env.VITE_TOKEN_EXCHANGE_THRESHOLD || 50
  },
  googleCalendarLink: googleCalendarDefaultLink,
  outlookCalendarLink: outlookCalendarDefaultLink,
  outlook365CalendarLink: outlook365CalendarDefaultLink,
  helpCenter: {
    baseUrl: 'https://support.coachhub.io/hc',
    coachPersona: '360003222097-I-m-a-Coach',
    coacheePersona: '4403239767569-I-m-a-Coachee'
  },
  supportEmail: 'support@coachhub.com',
  videoSessions: {
    performTechCheck: false
  },
  coachSelectionTipsPath: '/coach-proposal-tips',
  organizationIdForCoachOnboardingQuestionnaire: import.meta.env.VITE_COACH_ONBOARDING_ORG_ID!,
  tenants: {
    global: {
      coach: {
        url: import.meta.env.VITE_COACH_URL!
      },
      account: {
        url: import.meta.env.VITE_ACCOUNT_URL!
      },
      availability: {
        url: import.meta.env.VITE_AV_URL!
      },
      matching: {
        url: import.meta.env.VITE_MATCHING_URL!,
        timeout: toNumber(import.meta.env.VITE_MATCHING_TIMEOUT_THRESHOLD, 20000)
      },
      coaching: {
        url: import.meta.env.VITE_COACHING_URL
      },
      learningEngagement: {
        url: import.meta.env.VITE_LE_URL!
      }
    },
    local: {
      session: {
        urls: {
          'eu-1': import.meta.env.VITE_SESSION_URL,
          'us-1': import.meta.env.VITE_SESSION_URL_US
        }
      },
      document: {
        urls: {
          'eu-1': import.meta.env.VITE_DOCUMENT_URL,
          'us-1': import.meta.env.VITE_DOCUMENT_URL_US
        }
      },
      organization: {
        urls: {
          'eu-1': import.meta.env.VITE_ORGANIZATION_URL!,
          'us-1': import.meta.env.VITE_ORGANIZATION_URL_US!
        }
      },
      communication: {
        urls: {
          'eu-1': import.meta.env.VITE_COMMUNICATION_URL,
          'us-1': import.meta.env.VITE_COMMUNICATION_URL_US
        }
      },
      lineManager: {
        urls: {
          'eu-1': import.meta.env.VITE_LINE_MANAGER_URL,
          'us-1': import.meta.env.VITE_LINE_MANAGER_URL_US
        }
      },
      bff: {
        urls: {
          'eu-1': import.meta.env.VITE_BFF_URL,
          'us-1': import.meta.env.VITE_BFF_URL_US
        }
      },
      conferencing: {
        urls: {
          'eu-1': import.meta.env.VITE_CONFERENCING_URL,
          'us-1': import.meta.env.VITE_CONFERENCING_URL_US
        }
      }
    }
  },
  dashboardLink: import.meta.env.VITE_DASHBOARD_LINK!,
  zendesk: {
    apiKey: import.meta.env.VITE_ZENDESK_API_KEY,
    useDefaultCompany: import.meta.env.VITE_ZENDESK_USE_DEFAULT_COMPANY,
    testCompany: import.meta.env.VITE_ZENDESK_TEST_COMPANY,
    leaveUsAMessageTicketFormId: import.meta.env.VITE_ZENDESK_LEAVE_US_A_MESSAGE_TICKET_FORM_ID
  }
};

export default config;
