import { countryCodeEmoji } from 'country-code-emoji';
import countries from 'i18n-iso-countries';
import moment from 'moment';
import MomentTZ from 'moment-timezone';
import 'moment/locale/en-gb';
import { rightToLeftLanguages } from '../constants';
import { mappedLocales } from '../locales';
import dateFormats from './dateFormats';
import i18next from './i18next';
import TIMEZONES from './timezones';
import CONFIG_ENV from '../config/config-env';

// Set the key and value in the local storage
export const setLocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

// Get local storage based on key
export const getLocalStorage = key => {
  return localStorage.getItem(key);
};

export const getTokenFromLocalStorage = () => ({ Authorization: getLocalStorage('token') });

export const getBrowserLocale = ({ mapped }) => {
  const language = window.navigator.userLanguage || window.navigator.language;

  // Return mapped locale or if not known, fallback
  return mapped ? mappedLocales[language] : language;
};

/** @param {Date | string | number | import('moment').Moment} date */
export const isValidDate = date => moment(date).isValid();

/**
 * @param {string=} timezone
 * @param date
 * @param {date=} moment.Moment
 */
const hasAbbreviatedTimezoneCode = (timezone, date = moment()) =>
  timezone && !MomentTZ(date).tz(timezone).format(dateFormats.z).charAt(0).match(/[+-]/);

export const WEEK_FORMAT = 'week';
export const SHORT_FORMAT = 'short';
export const LONG_FORMAT = 'long';
export const LONG_FORMAT_WITHOUT_TIME = 'long_without_time';
export const LONG_WITH_TIMEZONE_FORMAT = 'long_with_timezone';
export const LONG_FORMAT_DATE_ONLY = 'long_format-date_only';
export const FORMAT_HOUR_AND_MINUTES = 'format_hour_and_minutes';
export const LONG_FORMAT_WITH_MONTH_ABBR = 'long_format_with_month_abbr';
export const SESSION_TIME = 45;

const _format = (date, format, timezone) => {
  if (timezone) {
    return MomentTZ(date).tz(timezone).format(format);
  }
  return moment(date).format(format);
};

export const formatDate = (date, lang, formatType, timezone) => {
  moment.locale(lang);
  let format = '';
  const hasAbbreviatedTzCode = hasAbbreviatedTimezoneCode(timezone, date);
  switch (formatType) {
    case SHORT_FORMAT:
      format = dateFormats.localeDayMonthYear;
      break;
    case LONG_FORMAT:
      format = dateFormats.localeDayNameAbbrDayMonthAbbrYearHourMinutes;
      break;
    case LONG_FORMAT_WITHOUT_TIME:
      return dateFormats.localeNumericDayAbbrWeekdayFullMonth(lang, date, timezone);
    case LONG_FORMAT_DATE_ONLY:
      format = dateFormats.localeDayFullMonthYear;
      break;
    case FORMAT_HOUR_AND_MINUTES:
      if (hasAbbreviatedTzCode) {
        format = dateFormats.HHmmz;
      } else {
        format = dateFormats.HHmmZOrGmtFallback(timezone);
      }
      break;
    case LONG_WITH_TIMEZONE_FORMAT:
      if (hasAbbreviatedTzCode) {
        return `${dateFormats.abbrWeekdayNumericDayFullMonthHourMinute(
          lang,
          date,
          timezone
        )} ${_format(date, dateFormats.z, timezone)}`;
      }
      return `${dateFormats.abbrWeekdayNumericDayFullMonthHourMinuteTimeZone(
        lang,
        date,
        timezone
      )} ${_format(date, dateFormats.abbreviatedTimeZone(timezone), timezone)}`;
    case LONG_FORMAT_WITH_MONTH_ABBR:
      format = dateFormats.DD_MMM_YYYY;
      break;
    default:
      return dateFormats.localeNumericDayAbbrWeekdayFullMonth(lang, date, timezone);
  }
  return _format(date, format, timezone);
};

export const isObject = obj => {
  return typeof obj === 'object' && obj !== null;
};

export const isObjectEmpty = obj => {
  return isObject(obj) && Object.keys(obj).length === 0;
};

export const removeEmptyObjectsFromArray = array => {
  return array.filter(obj => !isObjectEmpty(obj));
};

export const getUtcOffsetStrOrFallback = (dateIsoString, timezone) => {
  const tz = moment.utc(dateIsoString).tz(timezone).format('Z');
  const tzLetter = moment.utc(dateIsoString).tz(timezone).format(dateFormats.z);

  if (hasAbbreviatedTimezoneCode(timezone)) {
    return `${tzLetter} (UTC ${tz})`;
  }

  return `UTC ${tz}`;
};

export const getTimezone = userTimezone => {
  const foundTimezone = TIMEZONES.find(({ tzCode }) => tzCode === userTimezone);

  return foundTimezone ? foundTimezone.label : userTimezone;
};

export const sessionDateDuration = (date, lang, timezone, duration) => {
  if (!(duration && timezone && date)) {
    return '';
  }
  const formatWithMoment = (myDate, format) => {
    if (timezone) {
      return MomentTZ(myDate).tz(timezone).format(format);
    }
    return moment(myDate).format(format);
  };

  const formattedDate = dateFormats.localeNumericDayAbbrWeekdayAbbrMonthYear(lang, date, timezone);
  const start = formatWithMoment(date, dateFormats.localeHourMinute);
  const endTime = formatWithMoment(
    moment(date).add(parseInt(duration, 10), 'minutes').toDate(),
    dateFormats.localeHourMinute
  );
  return `${formattedDate} ${i18next.t('fromLabel')} ${start} ${i18next.t('toLabel')} ${endTime}`;
};

const today = moment().format(dateFormats.YYYYMMDD);
const yesterday = moment().subtract(1, 'day').format(dateFormats.YYYYMMDD);
const oneWeek = moment().subtract(7, 'days').format(dateFormats.YYYYMMDD);

export function formatWeekDate(date, lang, timezone) {
  moment.locale(lang);
  const formattedDate = moment(date).format(dateFormats.YYYYMMDD);
  if (formattedDate === today) {
    return i18next.t('today');
  }
  if (formattedDate === yesterday) {
    return i18next.t('yesterday');
  }
  if (moment(formattedDate).isBetween(oneWeek, today)) {
    return moment(formattedDate).format(dateFormats.dddd);
  }
  return formatDate(formattedDate, lang, SHORT_FORMAT, timezone);
}

export const subtractTimes = (startsAt, end) => {
  const ms = moment.utc(startsAt).diff(end);

  return moment.duration(ms);
};

export const getRandomInt = max => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const getFlagEmoji = countryCode => countryCodeEmoji(countryCode);

/**
 * Returns localized country name for the given country based on the given locale.
 * @param {*} countryCode two letter country code
 * @param {*} locale locale identifier which consists of language and region identifier, e.g. de_DE.
 *                   Only the language part will be considered
 * @returns localized country name for the given country
 */
export const getLocalizedCountryNameByLocale = (countryCode, locale) => {
  const lang = locale.substr(0, locale.indexOf('_'));
  let name = countries.getName(countryCode, lang, { select: 'official' });
  // 'US' is the country code coming from NEXMO API, u can add other countries if u want to render the 3 letter abbreviation for it instead of the full name. u can check the country code list https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
  if (['US'].includes(countryCode) && !rightToLeftLanguages.includes(locale)) {
    name = countries.getAlpha3Code(name, lang);
  }
  return name;
};

export function getLaunchDarklyUserId(user) {
  if (CONFIG_ENV.launchDarkly.useDefaultUser) {
    return CONFIG_ENV.launchDarkly.defaultUserId;
  }
  return user._id;
}

export const getLastUrlSegment = urlString => {
  return urlString.substring(urlString.lastIndexOf('/') + 1);
};

export const isSafariBrowser = !!(
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1
);

export const isEdgeBrowser = window.navigator.userAgent.indexOf('Edge') > -1;

export const checkIfTextIsRtlLang = text => {
  const rtlDirCheck = new RegExp(/[\u0590-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/);
  return rtlDirCheck.test(text);
};

export const groupBy = (arr, criteria) => {
  return arr.reduce((acc, currentValue) => {
    if (!acc[currentValue[criteria]]) {
      acc[currentValue[criteria]] = [];
    }
    acc[currentValue[criteria]].push(currentValue);
    return acc;
  }, {});
};

/**
 * Returns a formatted locale for native dates
 * Handle arabic gregorian format (agreement with Lokalisation team CHEE-797)
 */
export const getDateLocale = locale => {
  const formattedLocale = locale.replace('_', '-');
  const arabicLocales = ['ar_SA', 'ar-SA', 'ar_sa', 'ar-sa'];

  return arabicLocales.includes(formattedLocale) ? 'ar' : formattedLocale;
};

/**
 * Returns the number up to the nearest multiply of X number
 */
export const roundToNearestMultiplyOfXNumber = x => number => {
  return Math.ceil(number / x) * x;
};

/**
 * Returns the number up to the nearest multiply of 5
 */
export const roundToNearestMultiplyOf5 = roundToNearestMultiplyOfXNumber(5);
