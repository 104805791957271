import i18next from 'i18next';
// To prevent TypeScript errors/conflicts with other plugins, i18next-chained-backend recommends putting its import above other plugins.
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import CONFIG_ENV from '../config/config-env';
import { addPluralResolvers } from './addPluralResolvers';
import { DEFAULT_LANGUAGE } from '../model/model';

i18next
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    interpolation: {
      // React already does escaping
      escapeValue: false
    },
    simplifyPluralSuffix: true,
    lng: DEFAULT_LANGUAGE,
    ...(!CONFIG_ENV.localizations.forceUseOnlyBundledResources && {
      backend: {
        backends: [Backend],
        backendOptions: [
          {
            expirationTime: 24 * 60 * 60 * 1000, // 1 day
            loadPath: ([locale]: string[]) => {
              return `${CONFIG_ENV.localizations.backendUri}${locale}.json`;
            }
          }
        ]
      }
    }),
    fallbackLng: DEFAULT_LANGUAGE,
    debug: CONFIG_ENV.localizations.debug
  })
  .then(() => {
    // ensure plural resolvers are available for the application language
    addPluralResolvers();
  });

export default i18next;
