import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';

/**
 * exported for testing
 * @param {string} dataSourceName
 * * @param {string} environment
 * @returns {SentryOptions}
 */
export const __configureSentry = (dataSourceName, environment) => ({
  dsn: dataSourceName,
  debug: false,
  ignoreErrors: ['Material-UI:'],
  integrations: [
    Sentry.captureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['warn', 'error']
    }),
    Sentry.extraErrorDataIntegration({
      // limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 6
    }),
    Sentry.reactRouterV5BrowserTracingIntegration({ history: createBrowserHistory() })
  ],
  normalizeDepth: 7,
  ...(environment && { environment }),
  beforeBreadcrumb(crumb) {
    const isConsoleLog = crumb.category === 'console' && crumb.level === 'log';
    const isXhrRequest = crumb.category === 'xhr';
    const sendBreadcrumb = !isXhrRequest && !isConsoleLog;
    return sendBreadcrumb ? crumb : null;
  },
  tracesSampleRate: 1.0
});

/** @param {string} dataSourceName */
/** @param {string} environment` */
const initSentryMonitoring = (dataSourceName, environment) =>
  Sentry.init(__configureSentry(dataSourceName, environment));

export default initSentryMonitoring;
